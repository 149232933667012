<template>
  <v-container fill-height fluid>
    <v-card
      class="mx-auto pa-10"
      min-height="400"
      min-width="600"
      elevation="5"
    >
      <v-form ref="form" v-model="isValid" @submit.prevent="signIn">
        <v-card-title>
          <span class="mx-auto">Login</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
            v-model="email"
            label="Email"
            :rules="[...rules.required, ...rules.noSpaces, ...rules.email]"
          />
          <v-text-field
            v-model="password"
            label="Password"
            type="password"
            :rules="rules.required"
          />
        </v-card-text>
        <v-card-actions>
          <v-row align="center" no-gutters>
            <v-col class="pl-5" cols="6">
              <v-checkbox
                v-model="isRememberMe"
                label="Remember me"
              ></v-checkbox>
            </v-col>
            <v-col class="pr-5" align="end" cols="6">
              <v-btn
                type="submit"
                width="150"
                color="primary"
                :loading="isRequestWaiting"
                >Sign In</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-card>
    <SingleActionPopUp
      :isOpened="isErrorDialog"
      :title="'Login or password not valid'"
      :buttonText="'Ok'"
      @click="closeErrorDialog"
    ></SingleActionPopUp>
  </v-container>
</template>

<script>
import { tokenKey, refreshTokenKey } from "../utils/cookiesUtility.js";
import { GET_AUTHORIZATION_URL } from "../config/http";
import Rules from "../config/rules.js";
import SingleActionPopUp from "../components/shared/SingleActionPopUp.vue";

export default {
  name: "AuthPage",
  data: () => ({
    email: "",
    password: "",
    isValid: false,
    isRememberMe: localStorage.email !== undefined,
    isRequestWaiting: false,
    rules: Rules,
    isErrorDialog: false,
  }),
  created() {},
  methods: {
    signIn() {
      this.isRequestWaiting = true;
      this.$refs.form.validate();
      if (!this.isValid) {
        this.isRequestWaiting = false;
        return;
      }

      this.$http
        .post(GET_AUTHORIZATION_URL(), {
          email: this.email,
          password: this.password,
        })
        .then((response) => {
          response.json().then((data) => {
            console.log(data);

            if (this.isRememberMe) {
              localStorage.isRememberMe = this.isRememberMe;
              localStorage.email = this.email;
              localStorage.password = this.password;
            } else {
              localStorage.isRememberMe = false;
              localStorage.removeItem("email");
              localStorage.removeItem("password");
            }

            this.$cookies.set(tokenKey, data.data.token);
            this.$cookies.set(refreshTokenKey, data.data.refresh);

            this.$store.dispatch("trySetToken", {
              token: data.data.token,
            });
            this.$store.dispatch("trySetRefreshToken", {
              refreshToken: data.data.refresh,
            });
            this.$store.dispatch("restoreSession", {
              callback: () => {
                const myRoleId = this.$store.getters.roleId;

                const superAdminId = this.$store.getters.roleSuperAdmin.id;
                const CRMManagerId = this.$store.getters.roleCRMManager.id;
                const saleId = this.$store.getters.roleSale.id;
                const retId = this.$store.getters.roleRet.id;
                const supportId = this.$store.getters.roleSupport.id;
                const affManagerId = this.$store.getters.roleAffManager.id;
                const affTeamId = this.$store.getters.roleAffTeam.id;
                const retHeadId = this.$store.getters.roleRetHead.id;
                const retTeamId = this.$store.getters.roleRetTeam.id;
                const saleTeamId = this.$store.getters.roleSaleTeam.id;
                const saleHeadId = this.$store.getters.roleSaleHead.id;
                const superSuperAdminId =
                  this.$store.getters.roleSuperSuperAdmin.id;

                const callCenterId =
                  myRoleId !== superSuperAdminId
                    ? this.$store.getters.callCenter.id
                    : 0;

                // console.log(affTeamId);
                // console.log(retTeamId);
                // console.log(saleTeamId);
                // console.log(saleHeadId);
                // console.log(superSuperAdminId);
                // console.log(this.$store.getters.callCenter);
                // const callCenterId = 1;

                switch (myRoleId) {
                  case superSuperAdminId:
                    this.$router.push("/app/profiles");
                    break;

                  case superAdminId:
                    this.$router.push({
                      name: "CallCenterPage",
                      params: {
                        callCenterId,
                      },
                    });
                    break;

                  case CRMManagerId:
                    this.$router.push("/app/profiles");
                    break;

                  case saleId:
                    this.$router.push({
                      name: "CallCenterPage",
                      params: {
                        callCenterId,
                      },
                    });
                    break;

                  case retId:
                    this.$router.push({
                      name: "CallCenterPage",
                      params: {
                        callCenterId,
                      },
                    });
                    break;

                  case retHeadId:
                    this.$router.push({
                      name: "CallCenterPage",
                      params: {
                        callCenterId,
                      },
                    });
                    break;

                  case supportId:
                    this.$router.push({
                      name: "CallCenterPage",
                      params: {
                        callCenterId,
                      },
                    });
                    break;

                  case affManagerId:
                    this.$router.push({
                      name: "CallCenterPage",
                      params: {
                        callCenterId,
                      },
                    });
                    break;

                  case affTeamId:
                    this.$router.push({
                      name: "CallCenterPage",
                      params: {
                        callCenterId,
                      },
                    });
                    break;
                  case retTeamId:
                    this.$router.push({
                      name: "CallCenterPage",
                      params: {
                        callCenterId,
                      },
                    });
                    break;
                  case saleTeamId:
                    this.$router.push({
                      name: "CallCenterPage",
                      params: {
                        callCenterId,
                      },
                    });
                    break;
                  case saleHeadId:
                    this.$router.push({
                      name: "CallCenterPage",
                      params: {
                        callCenterId,
                      },
                    });
                    break;
                  default:
                    this.$router.push("/auth");
                    break;
                }

                // this.$router.push("/app/profiles");

                // const superAdminId = this.$store.getters.roleSuperAdmin.id;
                // const adminId = this.$store.getters.roleAdminCallCenter.id;
                // const clientManagerId =
                //   this.$store.getters.roleClientsManager.id;
                // const financialManagerId =
                //   this.$store.getters.roleFinancialManager.id;
                // const advertisingManagerId =
                //   this.$store.getters.roleAdvertisingManager.id;
                // const teamAdminId = this.$store.getters.roleTeamAdmin.id;

                // const callCenterId = this.$store.getters.callCenter?.id;

                // if (myRoleId === superAdminId) {
                //   this.$router.push("/app/profiles");
                // } else if (myRoleId === adminId) {
                //   this.$router.push({
                //     name: "CallCenterPage",
                //     params: {
                //       callCenterId,
                //     },
                //   });
                // } else if (myRoleId === clientManagerId) {
                //   this.$router.push({
                //     name: "CallCenterPage",
                //     params: {
                //       callCenterId,
                //     },
                //   });
                // } else if (myRoleId === financialManagerId) {
                //   this.$router.push({
                //     name: "CallCenterPage",
                //     params: {
                //       callCenterId,
                //     },
                //   });
                // } else if (myRoleId === advertisingManagerId) {
                //   this.$router.push({
                //     name: "CallCenterPage",
                //     params: {
                //       callCenterId,
                //     },
                //   });
                // } else if (myRoleId === teamAdminId) {
                //   this.$router.push({
                //     name: "CallCenterPage",
                //     params: {
                //       callCenterId,
                //     },
                //   });
                // } else {
                //   this.$router.push("/auth");
                // }
              },
            });
          });
        })
        .catch((e) => {
          console.error(e.message);
          this.isErrorDialog = true;
          this.isRequestWaiting = false;
        });
    },
    closeErrorDialog() {
      this.isErrorDialog = false;
    },
  },
  mounted() {
    // if (localStorage.isRememberMe !== undefined) {
    //   this.isRememberMe = localStorage.isRememberMe;
    // } else this.isRememberMe = true;
    if (localStorage.isRememberMe && localStorage.email !== undefined) {
      this.email = localStorage.email;
    }
    if (localStorage.isRememberMe && localStorage.password !== undefined) {
      this.password = localStorage.password;
    }
  },
  components: {
    SingleActionPopUp,
  },
};
</script>

<style></style>
