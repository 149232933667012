<template>
    <v-row no-gutters>
      <v-col>
        <v-row v-if="false">
          <v-col align="end">
            <v-btn @click="isCreateUserOpened = true" color="primary">
              Создать профиль
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <DataTableTop
              :isSeveralItemsSelected="isSeveralItemsSelected"
              v-model="searchQuery"
              @edit="openEditSeveralUsersPopUp()"
              @update="getUsersFromApi"
              @delete="openDeletePopUp()"
            ></DataTableTop>
  
            <v-data-table
              class="clickable-table"
              v-model="selectedItems"
              :headers="headers"
              :items="items"
              :page="currentPage"
              :items-per-page="currentPerPage"
              @click:row="userClickHandler"
              :loading="isRequestWaiting"
              :options.sync="options"
              :server-items-length="totalItems"
              :footer-props="{
                'items-per-page-options': rowsPerPageItems,
              }"
              show-select
              @pagination="setPage"
            >
              <template v-slot:header.email="{ header }">
                <FilterComponent
                  :header="header"
                  v-model="filterEmailQuery"
                  @update="getUsersFromApi"
                />
              </template>
              <template v-slot:header.firstName="{ header }">
                <FilterComponent
                  :header="header"
                  v-model="filterFirstNameQuery"
                  @update="getUsersFromApi"
                />
              </template>
              <template v-slot:header.lastName="{ header }">
                <FilterComponent
                  :header="header"
                  v-model="filterLastNameQuery"
                  @update="getUsersFromApi"
                />
              </template>
              <template v-slot:header.country="{ header }">
                <FilterComponent
                  :header="header"
                  v-model="filterCountryQuery"
                  @update="getUsersFromApi"
                />
              </template>
              <template v-slot:header.phone="{ header }">
                <FilterComponent
                  :header="header"
                  v-model="filterPhoneQuery"
                  @update="getUsersFromApi"
                />
              </template>
              <template v-slot:header.balance="{ header }">
                <FilterRangeComponent
                  :header="header"
                  v-model="filterBalanceQuery"
                  @update="getUsersFromApi"
                />
              </template>
              <template v-slot:header.createdAt="{ header }">
                <FilterDateRangeComponent
                  :header="header"
                  v-model="filterCreatedAtQuery"
                  @update="getUsersFromApi"
                />
              </template>
              <template v-slot:header.lastLoginDate="{ header }">
                <FilterDateRangeComponent
                  :header="header"
                  v-model="filterlastLoginDateQuery"
                  @update="getUsersFromApi"
                />
              </template>
              <template v-slot:header.processingStatusId="{ header }">
                <FilterProcessingStatusComponent
                  :isLead="true"
                  :header="header"
                  v-model="filterProcessingStatusQuery"
                  @update="getUsersFromApi"
                />
              </template>
              <template v-slot:header.LastComment="{ header }">
                <FilterComponent
                  :header="header"
                  v-model="filterLastCommentQuery"
                  @update="getUsersFromApi"
                />
              </template>
              <!-- <template v-slot:header.CallCenter.name="{ header }">
                <FilterCallCentersComponent
                  :header="header"
                  v-model="filterCallCenterQuery"
                  @update="getUsersFromApi"
                />
              </template> -->
              <template v-slot:header.AffManagerAdmin.firstName="{ header }">
                <FilterManagersComponent
                  :header="header"
                  :callCenterId="callCenterId"
                  v-model="filterAffManagerQuery"
                  role="aff"
                  @update="getUsersFromApi"
                />
              </template>
              <template v-slot:header.SaleAdmin.firstName="{ header }">
                <FilterManagersComponent
                  :header="header"
                  :callCenterId="callCenterId"
                  v-model="filterSaleAdminQuery"
                  role="sale"
                  @update="getUsersFromApi"
                />
              </template>
              <template v-slot:header.RetAdmin.firstName="{ header }">
                <FilterManagersComponent
                  :header="header"
                  :callCenterId="callCenterId"
                  v-model="filterRetAdminQuery"
                  role="ret"
                  @update="getUsersFromApi"
                />
              </template>
  
              <template v-slot:header.utm_source="{ header }">
                <FilterComponent
                  :header="header"
                  v-model="filterSourceQuery"
                  @update="getUsersFromApi"
                />
              </template>
  
              <template v-slot:header.utm_medium="{ header }">
                <FilterComponent
                  :header="header"
                  v-model="filterMediumQuery"
                  @update="getUsersFromApi"
                />
              </template>
  
              <template v-slot:header.utm_campaing="{ header }">
                <FilterComponent
                  :header="header"
                  v-model="filterCampaingQuery"
                  @update="getUsersFromApi"
                />
              </template>
  
              <template v-slot:header.utm_content="{ header }">
                <FilterComponent
                  :header="header"
                  v-model="filterContentQuery"
                  @update="getUsersFromApi"
                />
              </template>
  
              <template v-slot:header.depositsCount="{ header }">
                <FilterRangeComponent
                  :header="header"
                  v-model="filterDepositsCountQuery"
                  @update="getUsersFromApi"
                />
              </template>
              <template v-slot:header.depositsSum="{ header }">
                <FilterRangeComponent
                  :header="header"
                  v-model="filterDepositsSumQuery"
                  @update="getUsersFromApi"
                />
              </template>
  
              <template v-slot:header.verificationStatusId="{}">
                <thead>
                  <tr>
                    <th>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            style="margin-left: 6px"
                          >
                            mdi-help-circle-outline
                          </v-icon>
                        </template>
                        <span>
                          При клике на иконку статуса верификации откроется новое
                          всплывающее окно с выбором действий. Сам клик на иконку
                          не изменит статус пользователя, кликать на иконку
                          безопасно.
                        </span>
                      </v-tooltip>
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:item.icon="{ item }">
                <v-icon color="primary">
                  {{
                    item.isDuplicate
                      ? "mdi-account-multiple-outline"
                      : "mdi-account-circle"
                  }}
                </v-icon>
              </template>
              <template v-slot:item.call="{ item }">
                <v-btn @click.stop="startCall(item.phone)" color="primary" icon>
                  <v-icon>mdi-phone</v-icon>
                </v-btn>
              </template>
              <template v-slot:item.email="{ item }">
                <v-row no-gutters>
                  <v-col cols="12">
                    <span>{{ item.email }}</span>
                  </v-col>
                  <v-col cols="12">
                    {{
                      `(${
                        item.isEmailVerified ? "Подтвержден" : "Не подтвержден"
                      })`
                    }}
                  </v-col>
                </v-row>
              </template>
              <template v-slot:item.phone="{ item }">
                <v-row no-gutters align="center">
                  <v-col cols="6">
                    <span>{{ item.phone }}</span>
                  </v-col>
                  <v-col cols="1">
                    <v-btn
                      @click.stop="copyPhoneToClipboard(item.phone)"
                      color="primary"
                      small
                      icon
                    >
                      <v-icon color="primary" small> mdi-content-copy</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:item.balance="{ item }">
                {{ parseBigNumber(item.balance) }}
              </template>
              <template v-slot:item.AffManagerAdmin.firstName="{ item }">
                {{
                  ` ${
                    item.AffManagerAdmin
                      ? `${item.AffManagerAdmin.firstName} ${item.AffManagerAdmin.lastName}`
                      : ""
                  }`
                }}
              </template>
              <template v-slot:item.SaleAdmin.firstName="{ item }">
                {{
                  ` ${
                    item.SaleAdmin
                      ? `${item.SaleAdmin.firstName} ${item.SaleAdmin.lastName}`
                      : ""
                  }`
                }}
              </template>
              <template v-slot:item.RetAdmin.firstName="{ item }">
                {{
                  ` ${
                    item.RetAdmin
                      ? `${item.RetAdmin.firstName} ${item.RetAdmin.lastName}`
                      : ""
                  }`
                }}
              </template>
              <template v-slot:item.depositsSum="{ item }">
                {{ parseBigNumber(item.depositsSum) }}
              </template>
              <template v-slot:item.isFirstCall="{ item }">
                {{ item.isFirstCall ? "Да" : "Нет" }}
              </template>
              <template v-slot:item.createdAt="{ item }">
                {{ getFormatDate(item.createdAt) }}
              </template>
              <template v-slot:item.lastLoginDate="{ item }">
                {{ getFormatDate(item.lastLoginDate) }}
              </template>
              <template v-slot:item.LastComment="{ item }">
                <div
                  v-if="item.LastComment"
                  @click.stop="openContentInModal(item.LastComment.comment)"
                >
                  {{
                    item.LastComment.comment.length >= 30
                      ? item.LastComment.comment.substr(0, 30) + "..."
                      : item.LastComment.comment
                  }}
                </div>
              </template>
              <template v-slot:item.processingStatusId="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-badge
                      inline
                      left
                      dot
                      :color="processingStatusColor(item.processingStatusId)"
                    >
                      <span
                        v-bind="attrs"
                        v-on="on"
                        @click.stop="openEditProcessingStatusPopUp(item)"
                      >
                        {{
                          item.ProcessingStatus
                            ? item.ProcessingStatus.name
                            : null
                        }}
                      </span>
                    </v-badge>
                  </template>
                  <span>
                    {{
                      item.ProcessingStatus
                        ? item.ProcessingStatus.description
                        : null
                    }}
                  </span>
                </v-tooltip>
              </template>
              <template v-slot:item.verificationStatusId="{ item }">
                <v-btn
                  @click.stop="openVerificationPopUp(item)"
                  color="primary"
                  icon
                >
                  <v-icon>{{ getVerificationIcon(item) }}</v-icon>
                </v-btn>
              </template>
  
              <template v-slot:item.utm_source="{ item }">
                <div
                  v-if="item.utm_source"
                  @click.stop="openContentInModal(item.utm_source)"
                >
                  {{
                    item.utm_source.length >= 30
                      ? item.utm_source.substr(0, 30) + "..."
                      : item.utm_source
                  }}
                </div>
              </template>
  
              <template v-slot:item.utm_medium="{ item }">
                <div
                  v-if="item.utm_medium"
                  @click.stop="openContentInModal(item.utm_medium)"
                >
                  {{
                    item.utm_medium.length >= 30
                      ? item.utm_medium.substr(0, 30) + "..."
                      : item.utm_medium
                  }}
                </div>
              </template>
  
              <template v-slot:item.utm_campaing="{ item }">
                <div
                  v-if="item.utm_campaing"
                  @click.stop="openContentInModal(item.utm_campaing)"
                >
                  {{
                    item.utm_campaing.length >= 30
                      ? item.utm_campaing.substr(0, 30) + "..."
                      : item.utm_campaing
                  }}
                </div>
              </template>
  
              <template v-slot:item.utm_content="{ item }">
                <div
                  v-if="item.utm_content"
                  @click.stop="openContentInModal(item.utm_content)"
                >
                  {{
                    item.utm_content.length >= 30
                      ? item.utm_content.substr(0, 30) + "..."
                      : item.utm_content
                  }}
                </div>
              </template>
  
              <template v-slot:item.CallHistory="{ item }">
                {{ item[item.length - 1] }}
              </template>
              <template v-slot:item.isOnline="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                      :color="item.isOnline ? 'green' : 'red'"
                      size="18"
                      v-bind="attrs"
                      v-on="on"
                    ></v-avatar>
                  </template>
                  <span>
                    {{
                      item.isOnline
                        ? "Пользователь в сети"
                        : "Пользователь не в сети"
                    }}
                  </span>
                </v-tooltip>
              </template>
              <template v-slot:item.isOnTrading="{ item }">
                <span>{{ item.isOnTrading ? "Да" : "Нет" }}</span>
              </template>
              <template v-slot:item.edit="{ item }">
                <v-btn @click.stop="openEditPopUp(item)" color="primary" icon>
                  <v-icon>mdi-account-edit</v-icon>
                </v-btn>
              </template>
              <template v-slot:item.delete="{ item }">
                <v-btn
                  v-if="isSuperAdminPermission"
                  class="white--text"
                  @click.stop="openDeletePopUp([item])"
                  color="red"
                  icon
                >
                  <v-icon>mdi-account-remove</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
      <DetailUserPopUp
        :isOpened="isDetailPopUpOpened"
        :selectedItem="selectedItem"
        @openBids="openBidsHandler"
        @openActions="openActionsHandler"
        @openEdit="openEditPopUp($event)"
        @close="closeDetailPopUp"
      ></DetailUserPopUp>
      <VerificationUserPopUp
        :isOpened="isVerificationUserOpened"
        :selectedItem="selectedItem"
        @update="updateHandler($event)"
        @close="closeVerificationPopUp"
      ></VerificationUserPopUp>
      <EditUserPopUp
        :isOpened="isEditPopUpOpened"
        :selectedItem="selectedItem"
        @update="updateHandler($event)"
        @refresh="getUsersFromApi"
        @close="closeEditPopUp"
      ></EditUserPopUp>
      <ConfirmPopUp
        :question="'Вы действительно хотите удалить профиль(-и)?'"
        :isOpened="isDeletePopUpOpened"
        @yesAction="deleteUsers"
        @noAction="closeDeletePopUp"
        @close="closeDeletePopUp"
      ></ConfirmPopUp>
      <DetailBidsPopUp
        :isOpened="isDetailBidsPopUpOpened"
        :selectedItem="selectedItem"
        @close="closeBidsHandler"
      ></DetailBidsPopUp>
      <DetailActionsPopUp
        :isOpened="isDetailActionsPopUpOpened"
        :selectedItem="selectedItem"
        @close="closeActionsHandler"
      ></DetailActionsPopUp>
      <EditSeveralUsersPopUp
        :isOpened="isEditSeveralPopUpOpened"
        :selectedItem="selectedItems"
        @update="updateSeveralItems($event)"
        @close="closeEditSeveralUsersPopUp"
      ></EditSeveralUsersPopUp>
      <EditProcessingStatusPopUp
        :isOpened="isEditProcessingStatusPopUpOpened"
        :selectedItem="selectedItem"
        @update="updateHandler($event)"
        @refresh="getUsersFromApi"
        @close="closeEditProcessingStatusPopUp"
      />
      <OpenContentInModal
        :isOpened="isOpenContentInModal"
        :selectedItem="selectedItem"
        @update="updateHandler($event)"
        @close="closeContentInModal"
      ></OpenContentInModal>
    </v-row>
  </template>
  
  <script>
  import DetailUserPopUp from "../../callCenterPage/popUps/DetailUserPopUp.vue";
  import EditUserPopUp from "../popUps/EditUserPopUp.vue";
  import VerificationUserPopUp from "../popUps/VerificationUserPopUp.vue";
  import ConfirmPopUp from "../../shared/ConfirmPopUp.vue";
  
  import { GET_ALL_USERS_URL, GET_DELETE_USER_URL } from "../../../config/http";
  import appUIService from "../../../utils/appUIService";
  import DetailBidsPopUp from "../popUps/DetailBidsPopUp.vue";
  import DetailActionsPopUp from "../popUps/DetailActionsPopUp.vue";
  
  import DataTableMixin from "../../../mixins/DataTableMixin";
  import RoleMixin from "../../../mixins/RoleMixin";
  import TabMixin from "../../../mixins/TabMixin";
  import ProcessingStatusMixin from "../../../mixins/ProcessingStatusMixin";
  import HeadersMixin from "../../../mixins/HeadersMixin";
  import OpenContentInModal from "../popUps/OpenContentInModal.vue";
  
  export default {
    name: "AffManLeadsTab",
    mixins: [
      DataTableMixin,
      RoleMixin,
      TabMixin,
      ProcessingStatusMixin,
      HeadersMixin,
    ],
    data: () => ({
      headers: [],
      selectedItems: [],
      isVerificationUserOpened: false,
      isDetailBidsPopUpOpened: false,
      isDetailActionsPopUpOpened: false,
      isEditSeveralPopUpOpened: false,
      isOpenContentInModal: false,
    }),
    created() {
      this.getHeaders();
      appUIService.getInstance().userAddedCallback = this.addHandler;
    },
    destroyed() {
      appUIService.getInstance().userAddedCallback = () => {};
    },
    methods: {
      getHeaders() {
        this.getLeadsHeaders();
      },
      watcherHandler() {
        this.getUsersFromApi();
      },
      tabWatcherHandler() {
        this.getUsersFromApi();
      },
      getUsersFromApi() {
        this.isRequestWaiting = true;
        let params = this.getParams({
          // callCenterId: this.callCenterId,
          isClient: false,
        });
  
        this.$http
          .get(GET_ALL_USERS_URL(), { params })
          .then((response) => {
            response.json().then((response) => {
              // console.log(response);
              this.items = response.data.items;
              this.totalItems = response.data.count;
            });
          })
          .catch((e) => {
            console.error(e.message);
          })
          .finally(() => {
            this.isRequestWaiting = false;
          });
      },
      openContentInModal(user) {
        this.selectedItem = { user };
        this.isOpenContentInModal = true;
      },
  
      closeContentInModal() {
        this.isOpenContentInModal = false;
        this.selectedItem = null;
      },
      userClickHandler(user) {
        // console.log(user);
        this.openDetailPopUp(user);
      },
      openVerificationPopUp(user) {
        this.selectedItem = user;
        this.isVerificationUserOpened = true;
      },
      closeVerificationPopUp() {
        this.isVerificationUserOpened = false;
        this.selectedItem = null;
      },
      openDeletePopUp(item = null) {
        this.selectedItem = item;
        this.isDeletePopUpOpened = true;
      },
      closeDeletePopUp() {
        this.isDeletePopUpOpened = false;
        this.selectedItem = null;
      },
      deleteUsers() {
        this.isRequestWaiting = true;
  
        const usersIds = this.selectedItem
          ? this.selectedItem.id
          : this.selectedItems.map((item) => item.id).join(",");
  
        this.$http
          .delete(GET_DELETE_USER_URL(usersIds))
          .then((response) => {
            response.json().then(() => {
              // console.log(response);
              this.deleteSeveralHandler(this.selectedItems);
              this.selectedItems = [];
              this.getUsersFromApi();
            });
          })
          .catch((e) => console.error(e))
          .finally(() => {
            this.isRequestWaiting = false;
            this.closeDeletePopUp();
          });
      },
      openBidsHandler() {
        this.isDetailBidsPopUpOpened = true;
      },
      closeBidsHandler() {
        this.isDetailBidsPopUpOpened = false;
      },
      openActionsHandler() {
        this.isDetailActionsPopUpOpened = true;
      },
      closeActionsHandler() {
        this.isDetailActionsPopUpOpened = false;
      },
      openEditSeveralUsersPopUp() {
        if (this.isSeveralItemsSelected) {
          this.isEditSeveralPopUpOpened = true;
        }
      },
      closeEditSeveralUsersPopUp() {
        this.selectedItems = [];
        this.isEditSeveralPopUpOpened = false;
      },
      setPage(event) {
        this.$store.commit("setCallCenterLeadsPage", event.page);
        this.$store.commit("setCallCenterLeadsPerPage", event.itemsPerPage);
      },
      startCall(item) {
        window.open(`tel:${item}`, "_self");
      },
    },
    computed: {
      getNotVerificationStatus() {
        return this.$store.getters.notVerificationStatus.id;
      },
      getInProgressVerificationStatus() {
        return this.$store.getters.inProgressVerificationStatus.id;
      },
      getRejectVerificationStatus() {
        return this.$store.getters.rejectStatus.id;
      },
      getVerifiedVerificationStatus() {
        return this.$store.getters.verifiedStatus.id;
      },
      getCallCenter() {
        return this.$route.params.callCenter;
      },
      isSeveralItemsSelected() {
        return this.selectedItems.length > 0;
      },
      currentPage() {
        return this.$store.getters.CallCenterLeadsPage;
      },
      currentPerPage() {
        return this.$store.getters.CallCenterLeadsPerPage;
      },
    },
    components: {
      EditUserPopUp,
      VerificationUserPopUp,
      ConfirmPopUp,
      DetailUserPopUp,
      DetailBidsPopUp,
      DetailActionsPopUp,
      OpenContentInModal,
    },
    props: {
      callCenterId: Number,
    },
    mounted() {},
  };
  </script>
  
  <style></style>
  