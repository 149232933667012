<template>
  <v-dialog
    v-model="isOpened"
    max-width="600"
    @click:outside="closePopUp"
    persistent
  >
    <v-card class="px-6 py-4">
      <v-row>
        <v-col align="end">
          <v-btn @click="closePopUp" icon>
            <v-icon color="black"> mdi-close </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" no-gutters>
        <v-col cols="auto" align="center">
          <span class="text-h6"> Пригласить сотрудника </span>
        </v-col>
      </v-row>
      <v-form v-model="isValid" @submit.prevent="submitHandler" ref="form">
        <v-row no-gutters>
          <v-col>
            <v-text-field
              v-model="email"
              type="email"
              label="Email"
              placeholder="Email"
              :rules="[...rules.required, ...rules.noSpaces, ...rules.email]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-text-field
              v-model="firstName"
              label="Имя (псевдоним)"
              placeholder="Имя (псевдоним)"
              :rules="[...rules.required, ...rules.noSpaces]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-text-field
              v-model="lastName"
              label="Фамилия (псевдоним)"
              placeholder="Фамилия (псевдоним)"
              :rules="[...rules.required, ...rules.noSpaces]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row
          :class="selectedRole !== getSuperAdminRole.id ? 'mb-4' : null"
          no-gutters
        >
          <v-col>
            <v-autocomplete
              v-model="selectedRole"
              :items="getLowerLevelRoles"
              item-text="name"
              item-value="id"
              label="Роль"
              placeholder="Роль"
              :rules="[...rules.notNull]"
              ref="role"
              @dblclick="$refs.role.blur()"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <!-- <v-row v-if="selectedRole === getSuperAdminRole.id" no-gutters>
          <v-col>
            <v-autocomplete
              @change="selectedTeamChanged"
              :items="teams"
              item-text="name"
              item-value="id"
              label="Команда"
              placeholder="Команда"
              :loading="isRequestTeamsWaiting"
              ref="team"
              @dblclick="$refs.role.blur()"
            ></v-autocomplete>
          </v-col>
        </v-row> -->
        <!-- <v-row v-else-if="isTeamNeeded" no-gutters>
          <v-col>
            <v-autocomplete
              v-model="selectedTeams"
              :items="teams"
              item-text="name"
              item-value="id"
              label="Команда"
              placeholder="Команда"
              :loading="isRequestTeamsWaiting"
              ref="team"
              @dblclick="$refs.role.blur()"
              multiple
            ></v-autocomplete>
          </v-col>
        </v-row> -->
        <v-row class="mt-4" no-gutters>
          <v-col class="pa-1" cols="12">
            <v-btn
              color="primary"
              type="submit"
              :loading="isRequestWaiting"
              block
            >
              Пригласить
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mt-2" justify="center" v-if="success || error">
          <v-col cols="auto">
            <div class="text-h6" v-if="!success && error">
              <v-icon color="black"> mdi-alert-circle-outline </v-icon> Ошибка
              {{
                errorCode === 1
                  ? "- Эта почта уже зарегестрирована как клиент"
                  : ""
              }}
            </div>
            <div class="text-h6" v-if="success && !error">
              <v-icon color="black"> mdi-check-outline </v-icon> Успешно
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <SingleActionPopUp
      :buttonText="'Ок'"
      :isOpened="isPasswordOpened"
      :title="newUser ? `Пароль для ${newUser.email}: ${newUser.password}` : ''"
      @click="closePasswordModal"
    ></SingleActionPopUp>
  </v-dialog>
</template>

<script>
import PopUpMixin from "../../../mixins/PopUpMixin";
import {
  GET_ADD_ADMIN_URL,
  // GET_TEAMS_IN_CALLCENTER_URL,
} from "../../../config/http";
import appUIService from "../../../utils/appUIService";
import SingleActionPopUp from "../../shared/SingleActionPopUp.vue";

import RoleMixin from "../../../mixins/RoleMixin";

export default {
  name: "InviteEmployeePopUp",
  mixins: [PopUpMixin, RoleMixin],
  data: () => ({
    firstName: "",
    lastName: "",
    email: "",
    selectedRole: null,
    // selectedTeams: null,
    // teams: [],
    isPasswordOpened: false,
    newUser: null,
    // isRequestTeamsWaiting: false,
    isRequestCallCentersWaiting: false,

    error: false,
    errorCode: null,
    success: false,
  }),
  methods: {
    submitAction() {
      const body = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        roleId: this.selectedRole,
        // teams: this.selectedTeams,
        callCenterId: this.callCenterId,
      };
      this.$http
        .post(GET_ADD_ADMIN_URL(), body)
        .then((response) => {
          response.json().then((response) => {
            console.log(response);
            this.openPasswordModal(response.data.item);
            appUIService
              .getInstance()
              .employeeAddedCallback(response.data.item);
          });

          this.success = true;
          this.error = false;
          setTimeout(() => {
            this.success = false;
          }, 10000);
        })
        .catch((e) => {
          this.errorCode = e.body.code;
          console.log("aboba");
          console.log(e);
          console.log(this.errorCode);
          // console.error(e.message);
          this.isRequestWaiting = false;

          this.success = false;
          this.error = true;
          setTimeout(() => {
            this.error = false;
          }, 10000);
        });
    },
    getErrorRules() {
      return [() => this.errorCode !== 1 || "Ваше время вывода еще не пришло"];
    },
    // getTeamsForCallCenter() {
    //   this.isRequestTeamsWaiting = true;
    //   const params = {
    //     callCenterId: this.callCenterId,
    //   };

    //   this.$http
    //     .get(GET_TEAMS_IN_CALLCENTER_URL(), { params })
    //     .then((response) => {
    //       response.json().then((data) => {
    //         console.log(data);
    //         this.teams = data.data.items;
    //       });
    //     })
    //     .catch((e) => {
    //       console.error(e.message);
    //     })
    //     .finally(() => {
    //       this.isRequestTeamsWaiting = false;
    //     });
    // },
    isOpenedWatcher(value) {
      if (value) {
        this.isRequestWaiting = false;
        this.isValid = false;
        if (this.selectedItem) {
          this.itemCopy = { ...this.selectedItem };
        }
        // this.teams = [];
        // this.selectedTeams = null;
        this.$nextTick(() => {
          this.$refs.form.resetValidation();
        });
      }
      this.isPasswordOpened = false;
      this.newUser = null;
    },
    openPasswordModal(user) {
      this.newUser = user;
      this.isPasswordOpened = true;
    },
    closePasswordModal() {
      this.isPasswordOpened = false;
      this.newUser = null;

      this.$emit("close");
    },
    // selectedTeamChanged(item) {
    //   console.log(item);
    //   this.selectedTeams = [item];
    // },
  },
  computed: {
    // isTeamNeeded() {
    //   return (
    //     this.selectedRole === this.getSuperAdminRole.id ||
    //     this.selectedRole === this.getSuperAdminRole.id ||
    //     this.selectedRole === this.getSuperAdminRole.id ||
    //     this.selectedRole === this.getSuperAdminRole.id
    //   );
    // },
  },
  watch: {
    // selectedRole(value) {
    // this.teams = [];
    // this.selectedTeams = null;
    // if (value !== null) {
    //   this.getTeamsForCallCenter();
    // }
    // },
  },
  components: {
    SingleActionPopUp,
  },
  props: {
    callCenterId: Number,
  },
};
</script>

<style scoped></style>
