<template>
  <v-dialog
    v-model="isOpened"
    max-width="400"
    @click:outside="closePopUp"
    persistent
  >
    <v-card class="px-6 py-4">
      <v-row>
        <v-col align="end">
          <v-btn @click="closePopUp" icon>
            <v-icon color="black"> mdi-close </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" no-gutters>
        <v-col cols="auto" align="center">
          <span class="text-h6">
            <template v-if="isIncrease">
              Вы собираетесь пополнить баланс {{ getName }}
              {{ number ? `на ${number}` : "" }}, вы уверены?
            </template>
            <template v-else>
              Вы собираетесь cнять {{ number ? `${number}` : "" }} с баланса
              {{ getName }}, вы уверены?
            </template>
          </span>
        </v-col>
      </v-row>
      <v-form
        v-if="itemCopy"
        v-model="isValid"
        @submit.prevent="submitHandler"
        ref="form"
      >
        <v-row no-gutters>
          <v-col>
            <v-text-field
              v-model="number"
              @input="number = Math.abs(number)"
              @change="inputChangeHandler"
              type="number"
              label="Сумма"
              placeholder="Сумма"
              :rules="[...rules.requiredNumber, ...getErrorRules()]"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col>
            <v-textarea
              v-model="comment"
              @change="inputChangeHandler"
              label="Комментарий"
              placeholder="Комментарий"
              :rules="[...rules.requiredNumber, ...getErrorRules()]"
              rows="3"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row class="mt-2" no-gutters>
          <v-col cols="12">
            <v-btn
              color="primary"
              type="submit"
              :loading="isRequestWaiting"
              block
            >
              Депозит
            </v-btn>
          </v-col>
        </v-row>

        <v-row class="mt-2" justify="center" v-if="success || error">
          <v-col cols="auto">
            <div class="text-h6" v-if="!success && error">
              <v-icon color="black"> mdi-alert-circle-outline </v-icon> Ошибка
            </div>
            <div class="text-h6" v-if="success && !error">
              <v-icon color="black"> mdi-check-outline </v-icon> Успешно
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import PopUpMixin from "../../../mixins/PopUpMixin";
import RoleMixin from "../../../mixins/RoleMixin";

import { GET_UPDATE_BALANCE_USER_URL } from "../../../config/http";

export default {
  name: "EditBalancePopUp",
  mixins: [PopUpMixin, RoleMixin],
  props: {
    isIncrease: Boolean,
  },
  data: () => ({
    number: 0,
    comment: "",
    error: false,
    errorCode: null,
    success: false,
  }),
  methods: {
    submitAction() {
      this.isRequestWaiting = true;

      const amount = this.isIncrease
        ? parseFloat(this.number)
        : -parseFloat(this.number);

      const body = {
        amount,
        comment: this.comment,
      };

      if (this.isSuperAdminPermission) {
        body["isWithoutLimit"] = true;
      }

      if (this.itemCopy.balance + amount > 1000000000) {
        this.error = true;
        setTimeout(() => {
          this.error = false;
          this.$emit("close");
          this.isRequestWaiting = false;
        }, 10000);
      } else
        this.$http
          .patch(GET_UPDATE_BALANCE_USER_URL(this.itemCopy.id), body)
          .then((response) => {
            response.json().then((response) => {
              console.log(response);
              this.$emit("submit", false, response.data.item.balance);
              // this.$emit("close");
            });
            this.success = true;
            this.error = false;

            setTimeout(() => {
              this.success = false;
              this.$emit("close");
            }, 5000);
          })
          .catch((e) => {
            this.errorCode = e.body.code;
            this.$refs.form.validate();
            console.error(e.body.error);
            this.error = true;
            setTimeout(() => {
              this.error = false;
            }, 10000);
          })
          .finally(() => {
            this.isRequestWaiting = false;
          });
    },
    isOpenedWatcher(value) {
      if (value) {
        this.isRequestWaiting = false;
        this.isValid = false;
        if (this.selectedItem) {
          this.itemCopy = { ...this.selectedItem };
        }
        this.$nextTick(() => {
          this.$refs.form.reset();
        });
      }
    },
    getErrorRules() {
      return [
        () => this.errorCode !== 16 || "Ваше время вывода еще не пришло",
        () =>
          this.errorCode !== 17 ||
          "У вас недостаточно средств для вывода средств",
        () =>
          this.errorCode !== 18 ||
          "Достигнут максимальный вывод средств для статуса аккаунта",
      ];
    },
    inputChangeHandler() {
      this.errorCode = null;
      this.$refs.form.validate();
    },
  },
  computed: {
    getName() {
      return `${this.itemCopy.firstName} ${this.itemCopy.lastName}`;
    },
  },
};
</script>

<style scoped></style>
