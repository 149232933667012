<template>
  <span>
    {{ header.text }}
    <v-menu offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon small :color="filterQuery > 1 ? 'primary' : ''">
            mdi-filter
          </v-icon>
        </v-btn>
      </template>
      <div style="background-color: white; width: 280px">
        <v-autocomplete
          class="pl-4 pr-4"
          v-model="filterQuery"
          :items="items"
          :label="header.text"
          item-text="firstName"
          item-value="id"
          append-icon="mdi-close"
          @click:append="clear"
          @click="getEmployeeFromApi"
        >
          <template v-slot:item="data">
            {{ `${data.item.firstName} ${data.item.lastName}` }}
          </template>
        </v-autocomplete>

        <v-btn block @click="submit" color="primary"> Search </v-btn>
      </div>
    </v-menu>
  </span>
</template>

<script>
import { GET_ALL_ADMINS_IN_CALLCENTER_URL } from "../../config/http";
export default {
  name: "FilterManagersComponent",
  props: {
    header: Object,
    role: String,
    callCenterId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    filterQuery: "",
    items: [],
  }),
  methods: {
    clear() {
      this.filterQuery = "";
      this.$emit("input", "");
      this.$emit("update");
    },
    getEmployeeFromApi() {
      this.isRequestWaiting = true;

      const params = {};

      if (this.callCenterId) params["callCenterId"] = this.callCenterId;

      if (this.role == "aff") {
        // console.log(this.$store.getters.roleAffManager);
        params["roleId"] = this.$store.getters.roleAffManager.id;
      } else if (this.role == "ret") {
        // console.log(this.$store.getters.roleRet);
        params["roleId"] = this.$store.getters.roleRet.id;
      } else if (this.role == "sale") {
        // console.log(this.$store.getters.roleSale);
        params["roleId"] = this.$store.getters.roleSale.id;
      }

      this.$http
        .get(GET_ALL_ADMINS_IN_CALLCENTER_URL(), { params })
        .then((response) => {
          response.json().then((data) => {
            // console.log(data);
            this.items = data.data.items.concat([
              { id: null, firstName: "None", lastName: "" },
            ]);
            this.totalItems = data.data.count;
          });
        })
        .catch((e) => {
          console.error(e.message);
        })
        .finally(() => {
          this.isRequestWaiting = false;
        });
    },
    submit() {
      if (this.filterQuery) {
        this.$emit("input", this.filterQuery.toString());
        this.$emit("update");
      }
      if (this.filterQuery === null) {
        this.$emit("input", null);
        this.$emit("update");
      }
    },
  },
};
</script>

<style></style>
