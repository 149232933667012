<template>
  <v-row no-gutters>
    <v-col>
      <v-row v-if="false">
        <v-col align="end">
          <v-btn @click="isCreateUserOpened = true" color="primary">
            Создать профиль
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            class="clickable-table"
            v-model="selectedItems"
            :headers="headers"
            :items="items"
            @click:row="rowClickHandler"
            :loading="isRequestWaiting"
            :options.sync="options"
            :server-items-length="totalItems"
            hide-default-footer
          >
            <template v-slot:item.edit="{ item }">
              <v-btn @click.stop="openEditPopUp(item)" color="primary" icon>
                <v-icon>mdi-circle-edit-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>

    <EditLeveragePopUp
      :isOpened="isEditPopUpOpened"
      :selectedItem="selectedItem"
      @update="updateHandler($event)"
      @refresh="getDefaultLeverageFromApi"
      @close="closeEditPopUp"
    ></EditLeveragePopUp>
    <!-- <ConfirmPopUp
      :question="'Вы действительно хотите удалить профиль(-и)?'"
      :isOpened="isDeletePopUpOpened"
      @yesAction="deleteUsers"
      @noAction="closeDeletePopUp"
      @close="closeDeletePopUp"
    ></ConfirmPopUp> -->
  </v-row>
</template>

<script>
import BigNumber from "bignumber.js";
import EditLeveragePopUp from "../../profilesPage/popUps/EditLeveragePopUp.vue";
// import ConfirmPopUp from "../../shared/ConfirmPopUp.vue";
import { GET_CONSTANTS_URL } from "../../../config/http";

import DataTableMixin from "../../../mixins/DataTableMixin";
import RoleMixin from "../../../mixins/RoleMixin";

export default {
  name: "DefaultLeveragesTab",
  mixins: [DataTableMixin, RoleMixin],
  data: () => ({
    headers: [
      { text: "Актив", value: "name", sortable: true },
      { text: "Множитель", value: "multiplier", sortable: true },
      { text: "", value: "edit", sortable: false, width: 36 },
    ],
    selectedItems: [],
    isVerificationUserOpened: false,
    isDetailBidsPopUpOpened: false,
    isDetailActionsPopUpOpened: false,
    isEditSeveralPopUpOpened: false,
  }),
  methods: {
    getDefaultLeverageFromApi() {
      this.isRequestWaiting = true;
      this.$http
        .get(GET_CONSTANTS_URL())
        .then((response) => {
          response.json().then((response) => {
            // console.log("CONSTANTS:N");
            // console.log(response);
            // const response = data.data;
            this.items = response.data.Leverage;
            this.totalItems = response.data.Leverage.length;
            this.isRequestWaiting = false;
          });
        })
        .catch((e) => {
          console.error(e.message);
          this.isRequestWaiting = false;
        });
    },

    rowClickHandler(row) {
      // console.log(row);
      this.openEditPopUp(row);
    },
    openVerificationPopUp(user) {
      this.selectedItem = user;
      this.isVerificationUserOpened = true;
    },
    closeVerificationPopUp() {
      this.isVerificationUserOpened = false;
      this.selectedItem = null;
    },
    openEditPopUp(row) {
      // console.log(row);
      this.selectedItem = row;
      this.isEditPopUpOpened = true;
    },
    closeEditPopUp() {
      this.isEditPopUpOpened = false;
      this.selectedItem = null;
    },
    openDeletePopUp(item = null) {
      this.selectedItem = item;
      this.isDeletePopUpOpened = true;
    },
    closeDeletePopUp() {
      this.isDeletePopUpOpened = false;
      this.selectedItem = null;
    },
    parseBigNumber(number) {
      return new BigNumber(number).toFixed(2);
    },
    watcherHandler() {
      this.getDefaultLeverageFromApi();
    },
    openBidsHandler() {
      this.isDetailBidsPopUpOpened = true;
    },
    closeBidsHandler() {
      this.isDetailBidsPopUpOpened = false;
    },
    openActionsHandler() {
      this.isDetailActionsPopUpOpened = true;
    },
    closeActionsHandler() {
      this.isDetailActionsPopUpOpened = false;
    },
    openEditSeveralUsersPopUp() {
      if (this.isSeveralItemsSelected) {
        this.isEditSeveralPopUpOpened = true;
      }
    },
    closeEditSeveralUsersPopUp() {
      this.selectedItems = [];
      this.isEditSeveralPopUpOpened = false;
    },
  },
  computed: {
    getNotVerificationStatus() {
      return this.$store.getters.notVerificationStatus.id;
    },
    getInProgressVerificationStatus() {
      return this.$store.getters.inProgressVerificationStatus.id;
    },
    getRejectVerificationStatus() {
      return this.$store.getters.rejectStatus.id;
    },
    getVerifiedVerificationStatus() {
      return this.$store.getters.verifiedStatus.id;
    },
    isSeveralItemsSelected() {
      return this.selectedItems.length > 0;
    },
  },
  components: {
    EditLeveragePopUp,
    // ConfirmPopUp,
  },
};
</script>

<style></style>
