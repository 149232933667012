<template>
  <v-row no-gutters>
    <v-col>
      <v-row v-if="false">
        <v-col align="end">
          <v-btn @click="isCreateUserOpened = true" color="primary">
            Создать профиль
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <DataTableTop
            :isSeveralItemsSelected="isSeveralItemsSelected"
            v-model="searchQuery"
            @edit="openEditSeveralUsersPopUp()"
            @update="getUsersFromApi"
            @delete="openDeletePopUp()"
          ></DataTableTop>

          <v-data-table
            class="clickable-table"
            v-model="selectedItems"
            :headers="headers"
            :items="items"
            :page="currentPage"
            :items-per-page="currentPerPage"
            @click:row="userClickHandler"
            :loading="isRequestWaiting"
            :options.sync="options"
            :server-items-length="totalItems"
            :footer-props="{
              'items-per-page-options': rowsPerPageItems,
            }"
            show-select
            @pagination="setPage"
          >
            <template v-slot:header.email="{ header }">
              <FilterComponent
                :header="header"
                v-model="filterEmailQuery"
                @update="getUsersFromApi"
              />
            </template>
            <template v-slot:header.firstName="{ header }">
              <FilterComponent
                :header="header"
                v-model="filterFirstNameQuery"
                @update="getUsersFromApi"
              />
            </template>
            <template v-slot:header.lastName="{ header }">
              <FilterComponent
                :header="header"
                v-model="filterLastNameQuery"
                @update="getUsersFromApi"
              />
            </template>
            <template v-slot:header.country="{ header }">
              <FilterComponent
                :header="header"
                v-model="filterCountryQuery"
                @update="getUsersFromApi"
              />
            </template>
            <template v-slot:header.phone="{ header }">
              <FilterComponent
                :header="header"
                v-model="filterPhoneQuery"
                @update="getUsersFromApi"
              />
            </template>
            <template v-slot:header.balance="{ header }">
              <FilterRangeComponent
                :header="header"
                v-model="filterBalanceQuery"
                @update="getUsersFromApi"
              />
            </template>
            <template v-slot:header.createdAt="{ header }">
              <FilterDateRangeComponent
                :header="header"
                v-model="filterCreatedAtQuery"
                @update="getUsersFromApi"
              />
            </template>
            <template v-slot:header.lastLoginDate="{ header }">
              <FilterDateRangeComponent
                :header="header"
                v-model="filterlastLoginDateQuery"
                @update="getUsersFromApi"
              />
            </template>
            <template v-slot:header.processingStatusId="{ header }">
              <FilterProcessingStatusComponent
                :header="header"
                v-model="filterProcessingStatusQuery"
                @update="getUsersFromApi"
              />
            </template>
            <template v-slot:header.LastComment="{ header }">
              <FilterComponent
                :header="header"
                v-model="filterLastCommentQuery"
                @update="getUsersFromApi"
              />
            </template>
            <template v-slot:header.CallCenter.name="{ header }">
              <FilterCallCentersComponent
                :header="header"
                v-model="filterCallCenterQuery"
                @update="getUsersFromApi"
              />
            </template>
            <template v-slot:header.AffManagerAdmin.firstName="{ header }">
              <FilterManagersComponent
                :header="header"
                v-model="filterAffManagerQuery"
                role="aff"
                @update="getUsersFromApi"
              />
            </template>
            <template v-slot:header.SaleAdmin.firstName="{ header }">
              <FilterManagersComponent
                :header="header"
                v-model="filterSaleAdminQuery"
                role="sale"
                @update="getUsersFromApi"
              />
            </template>
            <template v-slot:header.RetAdmin.firstName="{ header }">
              <FilterManagersComponent
                :header="header"
                v-model="filterRetAdminQuery"
                role="ret"
                @update="getUsersFromApi"
              />
            </template>
            <template v-slot:header.depositsCount="{ header }">
              <FilterRangeComponent
                :header="header"
                v-model="filterDepositsCountQuery"
                @update="getUsersFromApi"
              />
            </template>
            <template v-slot:header.depositsSum="{ header }">
              <FilterRangeComponent
                :header="header"
                v-model="filterDepositsSumQuery"
                @update="getUsersFromApi"
              />
            </template>

            <template v-slot:header.verificationStatusId="{}">
              <thead>
                <tr>
                  <th>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          style="margin-left: 6px"
                        >
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      <span>
                        При клике на иконку статуса верификации откроется новое
                        всплывающее окно с выбором действий. Сам клик на иконку
                        не изменит статус пользователя, кликать на иконку
                        безопасно.
                      </span>
                    </v-tooltip>
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:item.icon="{}">
              <v-icon color="primary">mdi-account-circle</v-icon>
            </template>
            <template v-slot:item.email="{ item }">
              <v-row no-gutters>
                <v-col cols="12">
                  <span>{{ item.email }}</span>
                </v-col>
                <v-col cols="12">
                  {{
                    `(${
                      item.isEmailVerified ? "Подтвержден" : "Не подтвержден"
                    })`
                  }}
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.phone="{ item }">
              <v-row no-gutters align="center">
                <v-col cols="6">
                  <span>{{ item.phone }}</span>
                </v-col>
                <v-col cols="1">
                  <v-btn
                    @click.stop="copyPhoneToClipboard(item.phone)"
                    color="primary"
                    small
                    icon
                  >
                    <v-icon color="primary" small> mdi-content-copy</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.balance="{ item }">
              {{ parseBigNumber(item.balance) }}
            </template>
            <template v-slot:item.AffManagerAdmin.firstName="{ item }">
              {{
                ` ${
                  item.AffManagerAdmin
                    ? `${item.AffManagerAdmin.firstName} ${item.AffManagerAdmin.lastName}`
                    : ""
                }`
              }}
            </template>
            <template v-slot:item.SaleAdmin.firstName="{ item }">
              {{
                ` ${
                  item.SaleAdmin
                    ? `${item.SaleAdmin.firstName} ${item.SaleAdmin.lastName}`
                    : ""
                }`
              }}
            </template>
            <template v-slot:item.RetAdmin.firstName="{ item }">
              {{
                ` ${
                  item.RetAdmin
                    ? `${item.RetAdmin.firstName} ${item.RetAdmin.lastName}`
                    : ""
                }`
              }}
            </template>
            <template v-slot:item.depositsSum="{ item }">
              {{ parseBigNumber(item.depositsSum) }}
            </template>
            <template v-slot:item.isFirstCall="{ item }">
              {{ item.isFirstCall ? "Да" : "Нет" }}
            </template>
            <template v-slot:item.createdAt="{ item }">
              {{ getFormatDate(item.createdAt) }}
            </template>
            <template v-slot:item.lastLoginDate="{ item }">
              {{ getFormatDate(item.lastLoginDate) }}
            </template>
            <template v-slot:item.LastComment="{ item }">
              {{ item.LastComment ? item.LastComment.comment : "" }}
            </template>
            <template v-slot:item.processingStatusId="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-badge
                    inline
                    left
                    dot
                    :color="processingStatusColor(item.processingStatusId)"
                  >
                    <span
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="openEditProcessingStatusPopUp(item)"
                    >
                      {{
                        item.ProcessingStatus
                          ? item.ProcessingStatus.name
                          : null
                      }}
                    </span>
                  </v-badge>
                </template>
                <span>
                  {{
                    item.ProcessingStatus
                      ? item.ProcessingStatus.description
                      : null
                  }}
                </span>
              </v-tooltip>
            </template>
            <template v-slot:item.verificationStatusId="{ item }">
              <v-btn
                @click.stop="openVerificationPopUp(item)"
                color="primary"
                icon
              >
                <v-icon>{{ getVerificationIcon(item) }}</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.edit="{ item }">
              <v-btn @click.stop="openEditPopUp(item)" color="primary" icon>
                <v-icon>mdi-account-edit</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.delete="{ item }">
              <v-btn
                v-if="isSuperAdminPermission"
                class="white--text"
                @click.stop="openDeletePopUp([item])"
                color="red"
                icon
              >
                <v-icon>mdi-account-remove</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
    <DetailUserPopUp
      :isOpened="isDetailPopUpOpened"
      :selectedItem="selectedItem"
      @openBids="openBidsHandler"
      @openActions="openActionsHandler"
      @openEdit="openEditPopUp($event)"
      @close="closeDetailPopUp"
    ></DetailUserPopUp>
    <VerificationUserPopUp
      :isOpened="isVerificationUserOpened"
      :selectedItem="selectedItem"
      @update="updateHandler($event)"
      @close="closeVerificationPopUp"
    ></VerificationUserPopUp>
    <EditUserPopUp
      :isOpened="isEditPopUpOpened"
      :selectedItem="selectedItem"
      @update="updateHandler($event)"
      @refresh="getUsersFromApi"
      @close="closeEditPopUp"
    ></EditUserPopUp>
    <ConfirmPopUp
      :question="'Вы действительно хотите удалить профиль(-и)?'"
      :isOpened="isDeletePopUpOpened"
      @yesAction="deleteUsers"
      @noAction="closeDeletePopUp"
      @close="closeDeletePopUp"
    ></ConfirmPopUp>
    <DetailBidsPopUp
      :isOpened="isDetailBidsPopUpOpened"
      :selectedItem="selectedItem"
      @close="closeBidsHandler"
    ></DetailBidsPopUp>
    <DetailActionsPopUp
      :isOpened="isDetailActionsPopUpOpened"
      :selectedItem="selectedItem"
      @close="closeActionsHandler"
    ></DetailActionsPopUp>
    <EditSeveralUsersPopUp
      :isOpened="isEditSeveralPopUpOpened"
      :selectedItem="selectedItems"
      @update="updateSeveralItems($event)"
      @close="closeEditSeveralUsersPopUp"
    ></EditSeveralUsersPopUp>
    <EditProcessingStatusPopUp
      :isOpened="isEditProcessingStatusPopUpOpened"
      :selectedItem="selectedItem"
      @update="updateHandler($event)"
      @refresh="getUsersFromApi"
      @close="closeEditProcessingStatusPopUp"
    />
  </v-row>
</template>

<script>
import EditUserPopUp from "../../callCenterPage/popUps/EditUserPopUp.vue";
import VerificationUserPopUp from "../../callCenterPage/popUps/VerificationUserPopUp.vue";
import DetailBidsPopUp from "../../callCenterPage/popUps/DetailBidsPopUp.vue";
import ConfirmPopUp from "../../shared/ConfirmPopUp.vue";
import { GET_ALL_USERS_URL, GET_DELETE_USER_URL } from "../../../config/http";
import DetailUserPopUp from "../../callCenterPage/popUps/DetailUserPopUp.vue";
import DetailActionsPopUp from "../../callCenterPage/popUps/DetailActionsPopUp.vue";

import DataTableMixin from "../../../mixins/DataTableMixin";
import RoleMixin from "../../../mixins/RoleMixin";
import ProcessingStatusMixin from "../../../mixins/ProcessingStatusMixin";

export default {
  name: "ProfilesMainTab",
  mixins: [DataTableMixin, RoleMixin, ProcessingStatusMixin],
  data: () => ({
    headers: [
      { text: "", value: "icon", sortable: false, width: 36 },
      { text: "Email", value: "email", sortable: true },
      { text: "Имя", value: "firstName", sortable: true },
      { text: "Фамилия", value: "lastName", sortable: true },
      { text: "Страна", value: "country", sortable: true },
      { text: "Номер телефона", value: "phone", sortable: true },
      // { text: "Звонили ранее", value: "isFirstCall", sortable: true },
      { text: "Баланс", value: "balance", sortable: true, width: 100 },
      // { text: "IP Адрес", value: "ipAddress", sortable: true },
      { text: "Дата регистрации", value: "createdAt", sortable: true },
      { text: "Последняя авторизация", value: "lastLoginDate", sortable: true },
      { text: "Статус обработки", value: "processingStatusId", sortable: true },
      {
        text: "Последний комментарий",
        value: "LastComment",
        sortable: false,
        width: "350px",
      },
      { text: "Колл-центр", value: "CallCenter.name", sortable: true },
      //{ text: "Команда", value: "Team.name", sortable: true },
      {
        text: "Рекламный менеджер",
        value: "AffManagerAdmin.firstName",
        sortable: true,
      },
      {
        text: "Клиент-менеджер",
        value: "SaleAdmin.firstName",
        sortable: true,
      },
      {
        text: "Финансовый консультант",
        value: "RetAdmin.firstName",
        sortable: true,
      },
      { text: "Количество депозитов", value: "depositsCount", sortable: true },
      { text: "Сумма депозитов", value: "depositsSum", sortable: true },
      {
        text: "Верификация",
        value: "verificationStatusId",
        sortable: false,
      },
      { text: "", value: "edit", sortable: false, width: 36 },
      { text: "", value: "delete", sortable: false, width: 36 },
    ],
    selectedItems: [],
    isVerificationUserOpened: false,
    isDetailBidsPopUpOpened: false,
    isDetailActionsPopUpOpened: false,
    isEditSeveralPopUpOpened: false,
  }),
  methods: {
    getUsersFromApi() {
      this.isRequestWaiting = true;

      let params = this.getParams();

      this.$http
        .get(GET_ALL_USERS_URL(), { params })
        .then((response) => {
          response.json().then((data) => {
            // console.log(data);
            const response = data.data;
            this.items = response.items;
            this.totalItems = response.count;
            this.isRequestWaiting = false;
          });
        })
        .catch((e) => {
          console.error(e.message);
          this.isRequestWaiting = false;
        });
    },
    userClickHandler(user) {
      // console.log(user);
      this.openDetailPopUp(user);
    },
    openVerificationPopUp(user) {
      this.selectedItem = user;
      this.isVerificationUserOpened = true;
    },
    closeVerificationPopUp() {
      this.isVerificationUserOpened = false;
      this.selectedItem = null;
    },
    openEditPopUp(user) {
      this.selectedItem = user;
      this.isEditPopUpOpened = true;
    },
    closeEditPopUp() {
      this.isEditPopUpOpened = false;
      this.selectedItem = null;
    },
    openDeletePopUp(item = null) {
      this.selectedItem = item;
      this.isDeletePopUpOpened = true;
    },
    closeDeletePopUp() {
      this.isDeletePopUpOpened = false;
      this.selectedItem = null;
    },
    deleteUsers() {
      this.isRequestWaiting = true;

      const usersIds = this.selectedItem
        ? this.selectedItem.id
        : this.selectedItems.map((item) => item.id).join(",");

      this.$http
        .delete(GET_DELETE_USER_URL(usersIds))
        .then((response) => {
          response.json().then(() => {
            // console.log(response);
            this.deleteSeveralHandler(this.selectedItems);
            this.selectedItems = [];
            this.getUsersFromApi();
          });
        })
        .catch((e) => console.error(e))
        .finally(() => {
          this.isRequestWaiting = false;
          this.closeDeletePopUp();
        });
    },
    openDetailPopUp(item) {
      this.selectedItem = item;
      this.isDetailPopUpOpened = true;
    },
    closeDetailPopUp() {
      this.isDetailPopUpOpened = false;
      this.selectedItem = null;
    },
    watcherHandler() {
      this.getUsersFromApi();
    },
    openBidsHandler() {
      this.isDetailBidsPopUpOpened = true;
    },
    closeBidsHandler() {
      this.isDetailBidsPopUpOpened = false;
    },
    openActionsHandler() {
      this.isDetailActionsPopUpOpened = true;
    },
    closeActionsHandler() {
      this.isDetailActionsPopUpOpened = false;
    },
    openEditSeveralUsersPopUp() {
      if (this.isSeveralItemsSelected) {
        this.isEditSeveralPopUpOpened = true;
      }
    },
    closeEditSeveralUsersPopUp() {
      this.selectedItems = [];
      this.isEditSeveralPopUpOpened = false;
    },
    setPage(event) {
      this.$store.commit("setProfilesVerificationPage", event.page);
      this.$store.commit("setProfilesVerificationPerPage", event.itemsPerPage);
    },
  },
  computed: {
    getNotVerificationStatus() {
      return this.$store.getters.notVerificationStatus.id;
    },
    getInProgressVerificationStatus() {
      return this.$store.getters.inProgressVerificationStatus.id;
    },
    getRejectVerificationStatus() {
      return this.$store.getters.rejectStatus.id;
    },
    getVerifiedVerificationStatus() {
      return this.$store.getters.verifiedStatus.id;
    },
    isSeveralItemsSelected() {
      return this.selectedItems.length > 0;
    },
    currentPage() {
      return this.$store.getters.ProfilesVerificationPage;
    },
    currentPerPage() {
      return this.$store.getters.ProfilesVerificationPerPage;
    },
  },
  components: {
    EditUserPopUp,
    VerificationUserPopUp,
    ConfirmPopUp,
    DetailUserPopUp,
    DetailBidsPopUp,
    DetailActionsPopUp,
  },
};
</script>

<style></style>
